import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <footer className="bg-white rounded-lg shadow dark:bg-gray-900">
                <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                    <hr className="my-2 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                    <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">©2024 <Link to={"/"} className="hover:underline">Aura Aromas™</Link>. All Rights Reserved.</span>
                </div>
            </footer>

        </>
    )
}

export default Footer;