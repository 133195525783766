import React, { useEffect, useState } from 'react';
import { getDatabase, ref, child, get } from "firebase/database";
import db from '../firebase';


const Vieworder = () => {
    const [data, setData] = useState({});
    useEffect(() => {
        document.title = "View Orders";
        const dbRef = ref(getDatabase());
        get(child(dbRef, `Orders/`)).then((snapshot) => {
            if (snapshot.exists()) {
                setData({ ...snapshot.val() });
            } else {
                setData("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });

        return () => {
            setData({});
        }
    }, []);



    if(localStorage.getItem(process.env.LSN)){
    return (
        <>
            <h1 className="my-6 text-xl font-bold text-gray-900 text-center dark:text-white"> View order </h1>
            <div className="overflow-x-auto mx-auto shadow-md max-w-screen-xl sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Date and Time
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Product Category
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Recepient Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Phone Number
                            </th>
                            <th scope="col" className="px-6 py-3">
                                City / District
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Zone
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Address
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Price
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Item Description
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(data).map((id, index) => {
                            return (
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={id}>
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {data[id].date}
                                    </th>
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {data[id].category}
                                    </th>
                                    <td className="px-6 py-4">
                                        {data[id].recepientname}
                                    </td>
                                    <td className="px-6 py-4">
                                        {data[id].phone}
                                    </td>
                                    <td className="px-6 py-4">
                                        {data[id].city}
                                    </td>
                                    <td className="px-6 py-4">
                                        {data[id].zone}
                                    </td>
                                    <td className="px-6 py-4">
                                        {data[id].address}
                                    </td>
                                    <td className="px-6 py-4">
                                        {data[id].amount}
                                    </td>
                                    <td className="px-6 py-4">
                                        {data[id].description}
                                    </td>
                                </tr>
                            );
                        })};
                    </tbody>
                </table>
            </div>

        </>
    )
}else{
    return (
        <>
         <h1> Login to see details </h1>
        </>
    )
}
}
export default Vieworder;