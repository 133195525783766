import React, { useState, useEffect } from 'react';
import { Citylist, ZoneLists } from '../apidata/pathaoapidata';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, child, get } from "firebase/database";

const cities = {
    32: "B. Baria",
    52: "Bagerhat",
    62: "Bandarban",
    34: "Barguna",
    17: "Barisal",
    53: "Bhola",
    9: "Bogra",
    8: "Chandpur",
    15: "Chapainawabganj",
    2: "Chittagong",
    61: "Chuadanga",
    11: "Cox's Bazar",
    5: "Cumilla",
    1: "Dhaka",
    35: "Dinajpur",
    18: "Faridpur",
    6: "Feni",
    38: "Gaibandha",
    22: "Gazipur",
    56: "Gopalgonj",
    30: "Habiganj",
    41: "Jamalpur",
    19: "Jashore",
    27: "Jhalokathi",
    49: "Jhenidah",
    48: "Joypurhat",
    63: "Khagrachari",
    20: "Khulna",
    42: "Kishoreganj",
    55: "Kurigram",
    28: "Kushtia",
    40: "Lakshmipur",
    57: "Lalmonirhat",
    43: "Madaripur",
    60: "Magura",
    16: "Manikganj",
    50: "Meherpur",
    12: "Moulvibazar",
    23: "Munsiganj",
    26: "Mymensingh",
    46: "Naogaon",
    54: "Narail",
    21: "Narayanganj",
    47: "Narshingdi",
    14: "Natore",
    44: "Netrakona",
    39: "Nilphamari",
    7: "Noakhali",
    24: "Pabna",
    37: "Panchagarh",
    29: "Patuakhali",
    31: "Pirojpur",
    58: "Rajbari",
    4: "Rajshahi",
    59: "Rangamati",
    25: "Rangpur",
    51: "Satkhira",
    64: "Shariatpur",
    33: "Sherpur",
    10: "Sirajganj",
    45: "Sunamganj",
    3: "Sylhet",
    13: "Tangail",
    36: "Thakurgaon"
}
const initialState = {
    date: "",
    category: "",
    recepientname: "",
    phone: "",
    city: "",
    zone: "",
    address : "",
    amount: "",
    description: ""
}

const Addorder = () => {
    const [zones, setZones] = useState("0");
    let [state, setState] = useState(initialState);
    let { date, category, recepientname, phone, city, zone, address, amount, description } = state;
    const [data, setData] = useState({});


    const { id } = useParams();
    useEffect(() => {
        const dbRef = ref(getDatabase());
        get(child(dbRef, `Orders/`)).then((snapshot) => {
            if (snapshot.exists()) {
                setData({ ...snapshot.val() });
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });

        return () => {
            setData({});
        }
    }, [id]);

    useEffect(() => {
      document.title = "Add New Order";
    }, [])
    

    useEffect(() => {
        if (id) {
            setState({ ...data[id] })
        } else {
            setState({ ...initialState });
        }

        return () => {
            setState({ ...initialState });
        }
    }, [id, data])


    const twofun = (e) => {
        handleInputChange(e);
        zoneChange(e);
    }

    const zoneChange = (e) => {
        setZones(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!category || !recepientname || !phone || !city || !zone || !amount || !description || !address) {
            toast.error("Please fillup all field");
        } else {
            console.log(phone.toString().slice(0, 2))
            setState(city = cities[city]);
            let currentTime = new Date();
            let hours = currentTime.getHours();
            let minutes = currentTime.getMinutes();
            let curstring = currentTime.toString().slice(4, 10);
            setState(date = `${curstring}, ${hours}:${minutes}`);
            if (!id) {
                const res = fetch("https://aura-message-orders-default-rtdb.firebaseio.com/Orders.json",
                    {
                        method: "POST",
                        headers: {
                            "content_type": "application/json",
                        },
                        body: JSON.stringify({
                            date,
                            category,
                            recepientname,
                            phone,
                            city,
                            zone,
                            address,
                            amount,
                            description
                        })
                    }
                )
                if (res) {
                    toast.success("Order created successfully");
                } else {
                    toast.error("Something wrong");
                }
            } else {

                const usersRef = ref.child(`Orders/${id}`);
                usersRef.update({
                    state
                });
            }

        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    }

    if (localStorage.getItem(process.env.LSN)) {
        return (
            <>
                <section className="bg-white dark:bg-gray-900">
                    <div className="py-6 px-4 mx-auto max-w-2xl lg:py-14">
                        <h2 className="mb-6 text-xl font-bold text-gray-900 text-center dark:text-white">Add a new order</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="grid gap-4 sm:grid-cols-6 sm:gap-6">
                                <div>
                                    <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Product Category</label>
                                    <select id="category" name='category' value={category || ""} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                        <option selected="">Select category</option>
                                        <option value="AAN">AAN - 10 Piece 3ml</option>
                                        <option value="AAT">AAT - 12 Piece 3ml</option>
                                        <option value="Other">Other Order</option>
                                    </select>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="recepientname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Recepient Name</label>
                                    <input type="text" name="recepientname" value={recepientname || ""} id="recepientname" onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Type customer name" />
                                </div>
                                <div className="w-full">
                                    <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Recepient Phone Number</label>
                                    <input type="number" name="phone" id="phone" onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Enter customer phone number" required="true" value={phone} />
                                </div>

                                <div>
                                    <label htmlFor="city" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">City / District</label>
                                    <select id="city" name='city' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" onChange={twofun} value={city || ""}>
                                        <option value="0">Select district</option>
                                        {Citylist.map((data, key) => {
                                            return (
                                                <option key={key} value={data.city_id}>{data.city_name}</option>
                                            );
                                        })}
                                    </select>
                                </div>

                                <div>
                                    <label htmlFor="zone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Zone</label>
                                    <select id="zone" name='zone' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" onChange={handleInputChange} value={zone || ""}>
                                        <option value="0">Select zone</option>
                                        {ZoneLists[zones].map((data, key) => {
                                            return (
                                                <option key={key} value={data.zone_name} >{data.zone_name}</option>
                                            );
                                        })}
                                    </select>
                                </div>
                                
                                <div className="sm:col-span-2">
                                    <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Complete Address</label>
                                    <textarea id="address" name='address' rows="3" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Enter complete address" onChange={handleInputChange} value={address || ""}></textarea>
                                </div>

                                <div>
                                    <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Amount to collect </label>
                                    <input type="number" name="amount" id="amount" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Enter total amount" onChange={handleInputChange} value={amount || ""} />
                                </div>

                                <div className="sm:col-span-2">
                                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Item Description</label>
                                    <textarea id="description" name='description' rows="3" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Enter product description here" onChange={handleInputChange} value={description || ""}></textarea>
                                </div>
                            </div>
                            <button type="submit" value={id ? "Update" : "Add product"} className="inline-flex w-full justify-center items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800">{id ? "Update" : "Add Order"} </button>
                        </form>
                    </div>
                </section>
            </>
        )
    } else {
        return (
            <>
                <h1 classNameName='text-center py-5 text-4xl'> Login to see details </h1>
            </>
        )
    }
}
export default Addorder;