import React,{useEffect} from 'react';
import img from "../assets/Cover.jpg"
import { Link } from 'react-router-dom';

const Home = () => {
    const logout = () => {
        localStorage.clear();
        window.location.replace('/');
    }
    const login = () => {
        
    }
    useEffect(() => {
        document.title = "Aura Aromas Management - AAOMS";
      }, [])
    
    return (
        <>
            <div className="relative w-full">
                <div className="relative h-80 overflow-hidden rounded-lg md:h-96">
                    <div>
                        <img src={img} className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
                    </div>
                </div>
                <h1 className='text-center text-2xl mt-5 md:text-6xl'> Aura Aromas Order Management System (AAOMS) </h1>
                <p className='text-center text-xl mt-10'> Only for Aura Aromas officials </p>
                <div style={{display:"block", textAlign:"center",marginTop:"50px" }}>
                <Link to={localStorage.getItem(process.env.LSN) ? "/" : "/login"}>
                <button type="button" className="px-6 py-3.5 text-base font-medium text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={localStorage.getItem(process.env.LSN) ? logout : login}>
                    {localStorage.getItem(process.env.LSN) ? "Logout" : "Login for continue"}</button>
                </Link>
                </div>
            </div>

        </>
    )
}
export default Home;