import React, { useState ,useEffect} from 'react';
import { toast } from 'react-toastify';
import "../apidata/pathaoapidata";
import { EMAIL1, EMAIL2, EMAIL3, PASS1, PASS2, PASS3 } from '../apidata/pathaoapidata';

const Login = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    
    useEffect(() => {
        document.title = "Login - Aura Aromas";
      }, [])

    const handleSubmit = (e) =>{
        e.preventDefault();
        if (!email || !password) {
            toast.error("Please fill all field");
        } else {
            console.log(email,password);
            if((email ===EMAIL1  && password === PASS1) || (email === EMAIL2 && password === PASS2) || (email === EMAIL3 && password === PASS3)){
                localStorage.setItem(process.env.LSN , process.env.LSV);
                toast.success("successful");
                window.location.replace('/');
            }else{
                toast.error("Only Aura Aromas officials can login ");
            }
    }
}

    return (
        <div>
            <h1 className="px-4 mx-auto max-w-2xl  my-6 text-xl font-bold text-gray-900 text-center dark:text-white lg:py-14"> Login to continue </h1>
            <form className="max-w-sm mx-auto" onSubmit={handleSubmit}>
                <div className="mb-5">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" value>Your email</label>
                    <input type="email" id="email" name='email' value={email} onChange={(e) => setEmail(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your secret mail" required />
                </div>
                <div className="mb-5">
                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your password</label>
                    <input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your password" required />
                </div>
                <button type="submit" className="inline-flex w-full justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
            </form>

        </div>
    )
}

export default Login;