import React from "react";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from "./pages/Home";
import Addorder from "./pages/Addorder";
import Vieworder from "./pages/Vieworder";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "./components/header";
import Footer from "./components/footer";
import Login from "./pages/login";

function App() {
  return (
    <BrowserRouter>
      <div>
        <ToastContainer position="top-center"/>
      </div>
        <Header/>
      <Routes>
        <Route exact path='/' Component={Home}/>
        <Route  path='/add' Component={Addorder}/>
        <Route  path='/view' Component={Vieworder}/>
        <Route  path='/login' Component={Login}/>
        <Route  path='*' Component={Home}/>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
